import React from "react"

export default function EntryIntro({ section }) {
  return (
    <>
      {section === "EntryIntro" && (
        <div>
          <p>
            Welcome to Queensland Masters Squash Association (QMSA). <br />
            Submit your entry to our sanctioned tournaments here on this page.
          </p>

          <p>
            Please see tournament details and contact information in the{" "}
            <a href="https://ourvoice.qmsa.asn.au/calendar/">
              QMSA tournament calendar
            </a>.
          </p>

          <p>
            <a href="/check">
              <button type="button">Check Previous Entry</button>
            </a>
            <br />⬆️ Tip: Check if you entered already. Find players who have entered your tournament.
          </p>

          <p><em>
            Entries close <u>Tuesday before tournament</u>, or earlier as noted in
            calendar. Late entries may not be accepted.
          </em></p>

          <p>‼️ <a href="https://ourvoice.qmsa.asn.au/qmsa-sqashcomau-partnership-2024/" style={{ color: 'blueviolet' }}>Get your $30 discount
            for www.squash.com.au</a>
          </p>

        </div>
      )}

      {section === "EntrySubmitted" && <p>Thank you for your entry. </p>}
    </>
  )
}
